
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import JnDoc from "../../components/DocCard.vue";
import { document, search } from "ionicons/icons";
import { addIcons } from "ionicons";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Categories } from "@/models/enums.model";
import * as eventBus from "../../services/event.bus.js";
import JnGlobalSearchParams from "../search/GlobalSearchParams.vue";
import { useRouter, useRoute } from "vue-router";
import { SearchTypes } from "@/models/enums.model";

addIcons({
  document: document,
  search: search,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  alertController,
} from "@ionic/vue";
import { Picklist } from "@/models/picklist.model";

export default defineComponent({
  name: "JnHome",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    JnDoc,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonLoading,
    IonFab,
    IonFabButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();
    const scrollComponent = ref(null);

    const loading = ref(true);
    const timeout = ref(5000);

    const inEvidenza = ref([] as Picklist[]);
    const inEvidenzaIndex = ref([] as number[]);

    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES,
      from: 0,
      size: 10,
      idCategory: [Categories.ULTIME_NEWS],
      withText: true,
      searchInMassime: false,
    });

    const showAlert = async (message: string) => {
      const alert = await alertController.create({
        header: "Errore",
        message: message,
        buttons: [
          {
            text: "Riprova",
            role: "retry",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        await showAlert(err.message);
      }
    };

    const pushDocuments = async () => {
      searchData.from = docs.value.length;
      const allDocs = await EsService.getDocs(searchData);
      for (let i = 0; i < allDocs.length; i++) {
        docs.value.push(allDocs[i]);
      }
    };

    onBeforeMount(async () => {
      const tagsInEvidenza = await EsService.getPicklist(
        "home-page-in-evidenza"
      );
      let counter = 0;
      inEvidenzaIndex.value.push(counter);
      inEvidenza.value.push(new Picklist({ label: "News", value: "news" }));
      for (const tag of tagsInEvidenza) {
        if (tag.label.startsWith("app")) {
          counter++;
          tag.label = tag.label.split("#")[1];
          inEvidenza.value.push(tag);
          inEvidenzaIndex.value.push(counter);
        }
      }
      console.log(inEvidenza);
    });

    onMounted(async () => {
      loading.value = true;
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        loading.value = false;
        showAlert(err.message);
      }
      loading.value = false;
      eventBus.default.$on("reset-results", function (data: string) {
        docs.value = [];
        pushDocuments();
      });
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    const changeType = async (ev: CustomEvent) => {
      loading.value = true;
      docs.value = [];
      searchData.from = 0;
      if (ev.detail.value == "0") {
        searchData.idCategory = [Categories.ULTIME_NEWS];
        searchData.tags = [];
      } else {
        searchData.idCategory = [];
        searchData.tags = [inEvidenza.value[Number(ev.detail.value)].value];
      }
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        loading.value = false;
        showAlert(err.message);
      }
      loading.value = false;
    };

    const openSearchParams = async () => {
      const modal = await modalController.create({
        component: JnGlobalSearchParams,
        cssClass: "searchModal",
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        const searchData = result.data.value as SearchData;
        searchData.searchType = SearchTypes.ALL;
        searchData.searchDescription = "Globale";
        store.commit("searchData", searchData);
        eventBus.default.$emit("global-search-data", searchData);
        router.push("/main/search");
      }
    };

    pushDocuments();

    return {
      isDisabled,
      docs,
      scrollComponent,
      loadDocuments,
      timeout,
      loading,
      openSearchParams,
      changeType,
      inEvidenza,
      inEvidenzaIndex,
    };
  },
});
